import React, { useEffect, useState } from 'react';
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "./style.css";
import Environment from '../environment';
import secureLocalStorage from "react-secure-storage";
// const URL = 'https://192.168.2.8:4401/CheckUserLoginDetail';


export const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);

  const deviceID = `${userAgent}-${platform}-${randomString}`;
  // console.log(deviceID)

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = formData;

    try {
      const response = await axios.post(`${Environment.BaseAPIURL}/Login`, {
        userName: username,
        password: password,
        deviceId: ""
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.response === 'Y') {
          secureLocalStorage.setItem('username', username);
          localStorage.setItem('token', responseData.token);

          try {
            const permissionsResponse = await axios.get(`${Environment.BaseAPIURL}/GetPermissionListByUser?userid=${username}`, {
              headers: {
                Authorization: `Bearer ${responseData.token}`
              }
            });
            if (permissionsResponse.data && permissionsResponse.data.Data) {
              const permissionsData = permissionsResponse.data.Data;
              secureLocalStorage.setItem('permissions', JSON.stringify(permissionsData));
              setPermissions(permissionsData);
              const hasDashboardPermission = permissionsData.some(permission => permission.PageUrl === 'Dashboard');
              if (hasDashboardPermission) {
                navigate('/dashboard');
              } else {
                navigate('/welcome');
              }
            }
          } catch (error) {
            console.error('Error fetching Permissions:', error);
          }
        } else {
          setError('*' + responseData.message);
        }
      } else {
        setError('*200' + response.message);
      }
    } catch (error) {
      setError('Login failed: ' + error.message);
    }
  };


  return (
    <>
      <main className='backgroundStyle-login'>
        <div className="backgroundOverlay"></div>
        <div className='container'>
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className="custom-content">
                <div className='logo-login'>
                  <img src="/sources/logo2.png" alt="Logo" width="100" height="100" />
                </div>
                <form onSubmit={handleLogin} className='centerStyle mb-5'>
                  <div className='mb-4'>
                    <h3 className='PES'>Prohibited Baggage System</h3>
                    <p>Please sign in to your account</p>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text"><FaUser /></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      name="username"
                      required
                      autoComplete='off'
                      value={formData.username}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text"><FaLock /></span>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      required
                      autoComplete='off'
                      value={formData.password}
                      onChange={handleInput}
                    />
                    <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {error && <div className='error mb-1'>{error}</div>}
                  <button type="submit" className="button-custom">
                    Login
                  </button>
                  <div className="div-tagline"><p className='powered-by'>Powered by <span><img className='max-logo' src="/sources/maxworth.png" alt="Maxworth" width="100" height="100" /></span></p> </div>
                </form>
              </div>  
            </div>
          </div>
        </div>
      </main>
    </>
  );
};