import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Environment from '../environment';

export const Dummy = () => {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Move useNavigate to the top level

  const handleClick = async () => {
    try {
      const response = await axios.post(`${Environment.BaseAPIURL}/Login`, {
        username: name,
        password: pass,
        DeviceId: ""
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.response === 'Y') {
          navigate('/dashboard');
        } else {
          console.error('Invalid credentials');
        }
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const Response = await axios.get(`https://ka-f.fontawesome.com/releases/v6.5.2/css/free.min.css?token=7cf65f4e23`);
      if (Response.status === 200) {
        console.log("Loaded Successfully");
      }
    } catch (error) {
      console.error('Error loading CSS:', error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <div>
        <h1>Hello</h1>
        <div>
          <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="username" />
          <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" name="password" />
          <button onClick={handleClick}>Submit</button>
        </div>
      </div>
    </>
  );
};
