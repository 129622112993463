import "./style.css";

export const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <div className="card-footer">
                    © Maxworth Electronic Systems Pvt Ltd 2024. All rights reserved.
                </div>
                <div className="card-body">
                    <blockquote className="blockquote mb-0">
                        <p></p>
                        {/* <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer> */}
                    </blockquote>
                </div>
            </div>
        </footer>
    );
};
