import React, { useEffect, useRef } from 'react';
import { jwtDecode } from "jwt-decode";
import secureLocalStorage from 'react-secure-storage';

const LogoutTimer = ({ onLogout, timeoutDuration }) => {
    const isTokenExpired = (token) => {
        if (!token) return true;
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          return decodedToken.exp < currentTime;
        } catch (error) {
          console.error('Error decoding token:', error);
          return true;
        }
      };
    
      useEffect(() => {
        if (localStorage.getItem('token')) {
          const token = localStorage.getItem('token');
          if (isTokenExpired(token)) {
            alert("Session has been expired, please login again");
            localStorage.removeItem('token');
            secureLocalStorage.removeItem('permissions');
            secureLocalStorage.removeItem('username');
            window.location.href = '/';
          } 
        }
      }, []);
};

export default LogoutTimer;