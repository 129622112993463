import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlane, FaReceipt, FaSearch } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import "./styles.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Environment from '../environment';
import Pagination from './Pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import LogoutTimer from './LogoutTimer';


export const Passenger = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCheckpoint, setSelectedCheckpoint] = useState('Both');
  const [selectedAirline, setSelectedAirline] = useState('All');
  const [SelectedMethod, setSelectedMethod] = useState('All');
  const [SelectedMethod2, setSelectedMethod2] = useState('All'); 
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [CheckpointOptions, setCheckpointOptions] = useState([]);
  const token = localStorage.getItem('token');
  const handleSubmitClicked = useRef(true);


  // Pagination logic
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredData.length / pageSize);
  const [displayedData, setDisplayedData] = useState(filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  ));

  const indexOfFirstItem = currentPage * pageSize;
  const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    const startIndex = data.selected * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1);
    setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
  };

  const getAirlineName = (code) => {
    switch (code) {
      case 'All':
        return 'All Airlines';
      case '6E':
        return '6E - Indigo';
      case 'SG':
        return 'SG - Spice jet';
      case 'QP':
        return 'QP - Akasa Air';
      case '9I':
        return '9I - Alliance Air';
      case 'IC':
        return 'IC - Fly91';
      default:
        return '';
    }
  };
  const getSafeValue = (value) => {
    return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
  };

  const fetchAirlineCodes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${Environment.BaseAPIURL}/GetAirLineCode`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setAirlineOptions(response.data);
    } catch (error) {
      console.error('Error fetching airline codes:', error);
    }
  };

  useEffect(() => {
    fetchAirlineCodes();
  }, []);


  const fetchCheckpoints = async () => {
    try {
      const response = await axios.get(`${Environment.BaseAPIURL}/GetCheckPointNameList`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      setCheckpointOptions(response.data);
    } catch (error) {
      console.error('Error fetching airline codes:', error);
    }
  }

  useEffect(() => {
    fetchCheckpoints();
  }, []);

  const handleSubmit = () => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Environment.BaseAPIURL}/GetBaggageScanDataByDevice`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            InspectionMethod: SelectedMethod
          }
        });
        const filteredData = filterDataByDateAndCheckpoint(response.data);
        const sortedData = filteredData.sort((a, b) => new Date(a['Scan Date/Time']) - new Date(b['Scan Date/Time']));
        setSelectedMethod2(SelectedMethod);
        setData(sortedData);
        setFilteredData(sortedData);
        setDisplayedData(sortedData.slice(0, pageSize));
        setTotalEntries(sortedData.length);
        handleSubmitClicked.current = false;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
        setFilteredData([]);
        setDisplayedData([]);
        setTotalEntries(0);
      }
    };
    fetchData();
    
  };
  
  
  const handleMethodChange = (e) => {
    handleSubmitClicked.current = true;
    const selectedMethod = e.target.value;
    setSelectedMethod(selectedMethod);
  }
  

  const [startDate, setStartDate] = useState(() => {
    const defaultDate = new Date();
    defaultDate.setHours(0, 0, 0, 0);
    return defaultDate;
  });

  // Export To Excel Function
  const exportToExcel = () => {
    const dataToExport = displayedData.map((passenger, index) => ({
      "Sl. No.": index + 1,
      "Scan Date/Time": new Date(passenger.ScanedDateTime).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/\//g, '-'),
      "Pax Name": passenger.Name,
      "Flight No": passenger.FlightNumber,
      "Destination": passenger.Destination,
      "PNR": passenger.PNR,
      "Seat No.": passenger.Seat,
      "Sequence No.": passenger.SequenceNo,
      // "Airline Staff Name": passenger.AirlineStaffName,
      // "Airline Staff ID": passenger.AirlineStaffUserId,
      "CISF Name": passenger.CISFStaffName,
      "CISF ID": passenger.CISFStaffId,
      "ETD": passenger.ETD,
      "Prohibited Item Detected": passenger.ProhibitedItemDetected,
      "User ID": passenger.UserName,
      "Device ID": passenger.DeviceId,
      "Checkpoint Name": passenger.CheckPointName,
      "ETD Remark": passenger.ETDRemark
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "Baggage Scanned Summary Report.xlsx");
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCheckpointChange = (e) => {
    setSelectedCheckpoint(e.target.value);
  };

  const handleAirlineChange = (e) => {
    const selectedAirlineCode = e.target.value;
    setSelectedAirline(selectedAirlineCode);
  };
  

  const filterDataByDateAndCheckpoint = (data) => {
    const startDateTime = new Date(startDate);
    startDateTime.setSeconds(0);
    const endDateTime = new Date(endDate);
    endDateTime.setSeconds(0);

    const filteredData = data.filter(item => {
      const ScanedDateTime = new Date(item.ScanedDateTime);
      ScanedDateTime.setSeconds(0);
      const isWithinDateRange = (ScanedDateTime >= startDateTime && ScanedDateTime <= endDateTime);
      const isMatchingCheckpoint = selectedCheckpoint === 'Both' || item.CheckPointName === selectedCheckpoint;
      const isMatchingAirline = selectedAirline === 'All' || item.FlightNumber.substring(0, 2) === selectedAirline;
      return isWithinDateRange && isMatchingCheckpoint && isMatchingAirline;
    });

    console.log('Filtered Data:', filteredData);
    return filteredData;
  };


  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };
  const filterData = (searchTerm) => {
    const filtered = data.filter((passenger) =>
      Object.values(passenger).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
    setDisplayedData(filtered.slice(0, pageSize));
  };

  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  }, [currentPage, pageSize, filteredData]);

  return (
    <>
      <LogoutTimer />
      <Navbar />
      <main className="backgroundStyle">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <h1 className='AllpagesHeaderTxt'>
                <FaReceipt />
                Pax/Baggage Scanned Summary
              </h1>
            </div>
            <div className="col-md-12 col-xs-12 col-sm-12">
              <section className="scroller">
                <div className="custom-form row m-0">
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group DevicereportSelect">
                      <span className="input-group-text"><BiCalendar /> </span>
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        placeholderText="Select start date and time"
                        dateFormat="dd-MM-yyyy HH:mm"
                        timeFormat="HH:mm aa"
                        showTimeSelect
                        timeIntervals={15}
                        className="form-control"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group">
                      <span className=" input-group-text"><BiCalendar /></span>
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsStart
                        placeholderText="Select end date"
                        dateFormat="dd-MM-yyyy HH:mm"
                        timeFormat="HH:mm aa"
                        showTimeSelect
                        timeIntervals={15}
                        className="form-control"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group">
                      <span className="input-group-text"><img width="28" height="28" src='/sources/checkpoint.png' /></span>
                      <select className="form-select" value={selectedCheckpoint} onChange={handleCheckpointChange}>
                        <option value="Both">All Checkpoints</option>
                        {CheckpointOptions.map((checkpoint, index) => (
                          <option key={index} value={checkpoint.CheckPointName}>
                            {checkpoint.CheckPointName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group">
                      <span className="input-group-text"><FaPlane /> </span>
                      <select value={selectedAirline} onChange={handleAirlineChange} className="form-select">
                        {airlineOptions.map((airline, index) => (
                          <option key={index} value={airline.AirLineCode}>{getAirlineName(airline.AirLineCode)}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group">
                      <span className="input-group-text"><img width="26" height="26" src="https://img.icons8.com/windows/32/search--v1.png" alt="search--v1" /></span>
                      <select value={SelectedMethod} onChange={handleMethodChange} className="form-select">
                        <option value="All">All Inspection Method</option>
                        <option value="PHYSICAL">PHYSICAL</option>
                        <option value="ETD">ETD</option>
                        <option value="BOTH">BOTH</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
                    <div className="custom-input-group justify-content-xs-center">
                      <button className="filter-btn" onClick={handleSubmit}>
                        <span className=''>
                          Submit
                        </span>
                      </button>
                      <button className="refresh" onClick={() => window.location.reload()}>
                        <span className=''>
                          <img width="32" height="32" src="https://img.icons8.com/windows/32/000000/refresh.png" alt="refresh" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className='row'>
              <div className="col-md-12 col-sm-8 col-xs-12">
                <section className="scroller">
                  <div className="filter-box d-flex search-excel mb-4 justify-content-xs-center">
                    <div className="custom-input-group" style={{width: 300, marginRight: 35}}><span className="input-group-text"><FaSearch /></span>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control"
                      />
                    </div>
                    <div className="">
                      <button className='excel-button' onClick={exportToExcel} title="EXPORT">
                        <span className='excel-logo'>
                          <img width="24" height="24" src="./sources/excel.png" alt="export-excel" />
                        </span>
                        <span className="visually-hidden">EXPORT TO EXCEL</span>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className="table-header mb-3">
                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                  setPageSize(parseInt(e.target.value));
                  setCurrentPage(0);
                }}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className='showing-span'>
                  Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                </span>
              </div>
              <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <table id='custom-scroller' className="custom-table EntriesTableSection">
                  <thead className='t-header'>
                    <tr>
                      <th className='custom-header'>Sl. No.</th>
                      <th className='custom-header'>Scan Date/Time</th>
                      <th className='custom-header'>Pax Name</th>
                      <th className='custom-header'>Flight No.</th>
                      <th className='custom-header'>Destination</th>
                      <th className='custom-header'>PNR</th>
                      <th className='custom-header'>Seat No.</th>
                      <th className='custom-header'>Sequence No.</th>
                      <th className='custom-header'>CISF Name</th>
                      <th className='custom-header'>CISF ID</th>
                      {SelectedMethod2 !== 'PHYSICAL' && (
                        <>
                          <th className='custom-header'>ETD</th>
                          <th className='custom-header'>ETD Remark</th>
                        </>
                      )}
                      {SelectedMethod2 !== 'ETD' && (
                        <th className='custom-header'>Prohibited Item Detected</th>
                      )}
                      <th className='custom-header'>User ID</th>
                      <th className='custom-header'>Device ID</th>
                      <th className='custom-header'>Checkpoint Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(displayedData) && displayedData.length > 0 ? (
                      displayedData.map((passenger, index) => (
                        <tr key={passenger.id}>
                          <td className='custom-data'>{indexOfFirstItem + index + 1}</td>
                          <td className='custom-data'>
                            {new Date(passenger.ScanedDateTime).toLocaleString('en-GB', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: false
                            }).replace(/\//g, '-')}
                          </td>
                          <td className='custom-data'>{getSafeValue(passenger.Name)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.FlightNumber)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.Destination)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.PNR)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.Seat)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.SequenceNo)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.CISFStaffName)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.CISFStaffId)}</td>
                          {SelectedMethod2 !== 'PHYSICAL' && (
                            <>
                              <td className='custom-data'>{getSafeValue(passenger.ETD)}</td>
                              <td className='custom-data'>{getSafeValue(passenger.ETDRemark)}</td>
                            </>
                          )}
                          {SelectedMethod2 !== 'ETD' && (
                            <td className='custom-data'>{getSafeValue(passenger.ProhibitedItemDetected)}</td>
                          )}
                          <td className='custom-data'>{getSafeValue(passenger.UserName)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.DeviceId)}</td>
                          <td className='custom-data'>{getSafeValue(passenger.CheckPointName)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="no-data-message" colSpan="16">No data available.</td>
                      </tr>
                    )
                    }
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                    <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                  </li>
                </ul>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                    <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Passenger;