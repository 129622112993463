import React, { useState, useEffect } from 'react';
import { FaEdit, FaSearch } from "react-icons/fa";
import "./styles.css";
import Switch from 'react-switch';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import axios from 'axios';
import Environment from '../environment';
import Pagination from './Pagination';
import './Pagination.css';
import LogoutTimer from './LogoutTimer';
import SkeletonCard from './SkeletonCard';
import secureLocalStorage from 'react-secure-storage';


export const RoleRegistration = () => {

  const [ReportsToName, setReportsToName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [RoleName, setRoleName] = useState('');
  const [status, setStatus] = useState('Active');
  const [editingIndex, setEditingIndex] = useState(null);
  const [roleData, setRoleData] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [numberError, setNumberError] = useState('');
  const [accordionStates, setAccordionStates] = useState({});
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
  const [addUpdateClicked, setAddUpdateClicked] = useState(false);
  const [permissionError, setPermissionError] = useState('');
  const username = secureLocalStorage.getItem('username');
  const token = localStorage.getItem('token');
  const getSafeValue = (value) => {
    return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
  };

  const initialFormData = {
    RoleName: '',
    ReportsToName: '',
    status: true
  };

  const [emptyFields, setEmptyFields] = useState({
    RoleName: false,
    ReportsToName: false
  });

  const rolePermissions = {
    Dashboard: ['Dashboard'],
    Administration: ['Add Role', 'Add User', 'Add Device', 'Add Checkpoint', 'Add Prohibited Item'],
    // Operations: ['Add Baggage Details'],
    Reports: ['Pax/Baggage Summary', 'Device Wise Report']
  };

  const [isUpdatePopup, setIsUpdatePopup] = useState(false);

  const ConfirmationPopup = ({ showConfirmation, handleConfirmationClose, isUpdate }) => {
    return (
      showConfirmation && (
        <div>
          <div className="confirmation-overlay"></div>
          <div className="confirmation-popup">
            <h3 className='heading-popup'> {isUpdate ? 'Role Updated successfully' : 'Role Added successfully'}</h3>
            <button
              onClick={handleConfirmationClose}
              type="submit"
              className="close-button"
            >Close</button>
          </div>
        </div>
      )
    );
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Pagination

  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredData.length / pageSize);

  const [displayedData, setDisplayedData] = useState(filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  ));

  const indexOfFirstItem = currentPage * pageSize;
  const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);


  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    const startIndex = data.selected * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1);
    setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
  };

  const [formData, setFormData] = useState(initialFormData);

  const checkDuplicateRoleName = (roleName) => {
    return roleData.some(role => String(role.RoleName).toLowerCase() === roleName.toLowerCase());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const emptyFieldsCopy = { ...emptyFields };
    let hasEmptyField = false;

    if (!RoleName) {
      emptyFieldsCopy.RoleName = true;
      hasEmptyField = true;
    } else {
      emptyFieldsCopy.RoleName = false;
    }

    if (!ReportsToName) {
      emptyFieldsCopy.ReportsToName = true;
      hasEmptyField = true;
    } else {
      emptyFieldsCopy.ReportsToName = false;
    }

    setEmptyFields(emptyFieldsCopy);

    if (hasEmptyField) {
      return;
    }

    if (editingIndex === null && checkDuplicateRoleName(RoleName)) {
      setNumberError('*Role already exists.');
      return;
    }

    if (selectedRolePermissions.length === 0) {
      setPermissionError('Please assign at least one permission');
      return;
    }

    const isAddAction = editingIndex === null;

    let formattedPermissions;
    let permissionisactive;

    if (isAddAction) {
      // For add action, use only selected permissions and set permissionisactive to '1'
      formattedPermissions = selectedRolePermissions.join(',');
      permissionisactive = '1';
    } else {
      // For update action, include all permissions with their respective states
      const permissionsMap = {};
      Object.keys(rolePermissions).forEach(roleKey => {
        rolePermissions[roleKey].forEach(permission => {
          permissionsMap[permission] = selectedRolePermissions.includes(permission) ? '1' : '0';
        });
      });
      formattedPermissions = Object.keys(permissionsMap).join(',');
      permissionisactive = Object.values(permissionsMap).join(',');

      // const noPermissionsSelected = Object.values(permissionsMap).every(state => state === '0');
      // if (noPermissionsSelected) {
      //   setPermissionError('Please assign at least one permission');
      //   return;
      // }
    }

    setPermissionError('');
    setNumberError('');

    const requestData = {
      action: isAddAction ? 'Add' : 'Update',
      permissionName: formattedPermissions,
      permissionisactive: permissionisactive,
      isactive: status ? 1 : 0,
      roleName: RoleName,
      reportsToName: ReportsToName,
      CreatedByUser: username
    };

    try {
      const response = await axios.post(
        `${Environment.BaseAPIURL}/AddUpdateRolePermission`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status === 200) {
        const data = response.data;
        if (data.response === "Role Exists") {
          setNumberError('*Role already exists.');
          return;
        }
        const updatedResponse = await axios.get(`${Environment.BaseAPIURL}/GetUserReportsToDetail`,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const updatedDeviceData = updatedResponse.data;
        setRoleData(updatedDeviceData);
        setFilteredData(updatedResponse.data);
        setFormData(initialFormData);
        setShowConfirmation(true);
        setIsUpdatePopup(editingIndex !== null);
      } else {
        alert('Failed to add/update Role. Please try again later.');
      }
    } catch (error) {
      alert('An error occurred while processing your request. Please try again later.');
    }
    setAddUpdateClicked(false);
    handleReset();
    // const permissionsResponse = await axios.get(`${Environment.BaseAPIURL}/GetPermissionListByUser?userid=${localStorage.getItem('username')}`);
    //   if (permissionsResponse.data && permissionsResponse.data.Data) {
    //     const permissions = permissionsResponse.data.Data;
    //     localStorage.setItem('permissions', JSON.stringify(permissions));
    //   }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, roleResponse] = await Promise.all([
          axios.get(`${Environment.BaseAPIURL}/GetUserReportsToDetail`,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          }),
          axios.get(`${Environment.BaseAPIURL}/GetRoleList`,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        ]);
        setRoleData(userResponse.data);
        setFilteredData(userResponse.data);
        setRoleList(roleResponse.data);
        setDisplayedData(userResponse.data.slice(0, pageSize));

        // const permissionsResponse = await axios.get(`${Environment.BaseAPIURL}/GetPermissionListByUser?userid=${localStorage.getItem('username')}`);
        // if (permissionsResponse.data && permissionsResponse.data.Data) {
        //   const permissions = permissionsResponse.data.Data;
        //   localStorage.setItem('permissions', JSON.stringify(permissions));
        // }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleAccordionPermissionToggle = (permission, accordionIndex) => {
    setSelectedRolePermissions((prevPermissions) => {
      const updatedPermissions = prevPermissions.includes(permission)
        ? prevPermissions.filter((p) => p !== permission)
        : [...prevPermissions, permission];
      return updatedPermissions;
    });
  };



  const handleEdit = async (index) => {
    handleReset();
    const actualIndex = currentPage * pageSize + index;
    const editedDevice = filteredData[actualIndex];
  
    // Set role details
    setRoleName(editedDevice.RoleName);
    setReportsToName(editedDevice.ReportsTo);
    setStatus(editedDevice.Status === 'Active');
    setEditingIndex(actualIndex);
  
    // Fetch permissions for the selected role
    try {
      const response = await axios.post(`${Environment.BaseAPIURL}/GetPermissionList`, {
        roleName: editedDevice.RoleName,
        reportsTo: editedDevice.ReportsTo
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  

      if (response.status === 200 && response.data.permissionsList) {
        const fetchedPermissions = response.data.permissionsList.flatMap((permission) =>
          permission.SubPermissions
            .filter((SubPermission) => SubPermission.IsActive === 1)
            .map((SubPermission) => SubPermission.Name)
        );
        setSelectedRolePermissions(fetchedPermissions);
        // Open accordions with permissions
        response.data.permissionsList.forEach((permission, idx) => {
          if (permission.SubPermissions.some((SubPermission) => SubPermission.IsActive === 1)) {
            const accordionButton = document.querySelector(`#panelsStayOpen-heading${idx} .accordion-button`);
            if (accordionButton && accordionButton.classList.contains('collapsed')) {
              accordionButton.click();
            }
          }
        });
      } else {
        alert('Failed to fetch permissions for the selected role. Please try again later.');
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
      alert('An error occurred while fetching permissions. Please try again later.');
    }
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  
    // Reset empty field indicators
    setEmptyFields({
      RoleName: false,
      ReportsToName: false
    });
  };
  


  const handleReset = () => {
    setRoleName('');
    setReportsToName('');
    setStatus(true);
    setEditingIndex(null);
    setSelectedRolePermissions([]);
    setPermissionError('');
    setEmptyFields({
      RoleName: false,
      ReportsToName: false
    });
    setNumberError('');

    const newAccordionStates = {};
    Object.keys(rolePermissions).forEach((roleKey, index) => {
      newAccordionStates[index] = false;
    });
    setAccordionStates(newAccordionStates);

    // Deselect all permissions
    setSelectedRolePermissions([]);

  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (searchTerm) => {
    const filtered = roleData.filter((passenger) =>
      Object.values(passenger).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
    setDisplayedData(filtered.slice(0, pageSize));
  };


  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage, pageSize]);


  return (
    <>
      <LogoutTimer />
      <Navbar />
      <main className=''>
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12 mt-3'>
              <h1 className='AllpagesHeaderTxt'>
                <img width="38" height="38" src="/sources/pass-data.png" alt="person-male--v3" />
                Role
              </h1>
            </div>
            <div className="col-md-8 col-xs-12 col-sm-12 role-padding">
              <form className="custom-form row m-0" onSubmit={handleFormSubmit}>
                <div className={`col-md-6 col-sm-4 col-xs-6 mb-4 ${emptyFields.RoleName ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/ios-glyphs/30/group-foreground-selected.png" alt="" /></span>
                    <input className="form-control" type="text" value={RoleName} placeholder="Role" onChange={(e) => {
                      setRoleName(e.target.value);
                      setNumberError('');
                      if (e.target.value !== '') {
                        setEmptyFields(prevState => ({ ...prevState, RoleName: false }));
                      };
                      if (checkDuplicateRoleName(e.target.value)) {
                        setNumberError('*Role already exists.');
                      }
                    }} disabled={editingIndex !== null} />
                  </div>
                  {numberError && <p className="error-message">{numberError}</p>}
                  {emptyFields.RoleName && <div className="error-message">*Role is required</div>}
                </div>
                <div className={`col-md-6 col-sm-4 col-xs-6 mb-4 ${emptyFields.ReportsToName ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/ios-filled/50/administrator-male--v1.png" alt="" /></span>
                    <select
                      className='drop-item form-control form-select sel-items'
                      value={ReportsToName}
                      onChange={(e) => {
                        setReportsToName(e.target.value);
                        if (e.target.value !== '') {
                          setEmptyFields(prevState => ({ ...prevState, ReportsToName: false }));
                        };
                      }}
                    >
                      <option value="" disabled>Select Reporting Manager</option>
                      {RoleList.map((reports, index) => (
                        <option key={index} value={reports.RoleName}>{reports.RoleName}</option>
                      ))}
                    </select>
                  </div>
                  {emptyFields.ReportsToName && <div className="error-message">*Reporting Manager is required</div>}
                </div>
                <div className='col-md-12 col-xs-2 col-sm-10 text-center mt-4'>
                  <div className='sub-buttons'>
                    {editingIndex !== null && (
                      <Switch
                        checked={status}
                        onChange={() => setStatus(!status)}
                      />
                    )}
                    {editingIndex !== null ? (
                      <>
                        <button type="button" onClick={handleReset} className=" reset">Reset</button>
                        <button type="submit" className="add">Update</button>
                      </>
                    ) : (
                      <>
                        <button type="button" onClick={handleReset} className=" reset">Reset</button>
                        <button type="submit" className="add">Add</button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4 col-xs-12 col-sm-12">
              <div className='custom-perm'>
                <div>
                  <h3>Assign Permissions</h3>
                </div>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {Object.keys(rolePermissions).map((roleKey, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" style={{ height: 40 }} id={`panelsStayOpen-heading${index}`}>
                        <button
                          className="accordion-button collapsed"
                          style={{ padding: 10, backgroundColor: '#ededed' }}
                          type="button"
                          onClick={() => setAccordionStates(prevState => ({ ...prevState, [index]: !prevState[index] }))}
                          aria-expanded={accordionStates[index]}
                          aria-controls={`panelsStayOpen-collapse${index}`}
                        >
                          {roleKey.toUpperCase()}
                        </button>
                      </h2>
                      <div
                        id={`panelsStayOpen-collapse${index}`}
                        className={`accordion-collapse collapse ${accordionStates[index] ? 'show' : ''}`}
                        aria-labelledby={`panelsStayOpen-heading${index}`}
                      >
                        <div className="accordion-body" style={{ padding: 10 }}>
                          {rolePermissions[roleKey].map((permission, idx) => (
                            <div
                              key={idx}
                              onClick={() => handleAccordionPermissionToggle(permission, index)}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: selectedRolePermissions.includes(permission) ? '#cceeff' : 'transparent',
                                borderRadius: '5px',
                              }}
                            >
                              {selectedRolePermissions.includes(permission) ? <strong>{permission}</strong> : permission}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {permissionError && (
                  <div className="error-message">{permissionError}</div>
                )}
              </div>
            </div>
            <div className='row mb-3 mt-3'>
              <div className="col-md-12 col-sm-8 col-xs-12">
                <section className="scroller">
                  <div className="filter-box d-flex search-excel">
                    <div className="custom-input-group" style={{width: 300}}><span className="input-group-text"><FaSearch /></span>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12 mb-5'>
              <div className="table-header mb-3">
                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                  setPageSize(parseInt(e.target.value));
                  setCurrentPage(0);
                }}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className='showing-span'>
                  Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                </span>
              </div>
              <div className="table-responsive" style={{ maxHeight: '459px', overflowY: 'auto' }}>
                <table className="custom-table userEntriesTableSection">
                  <thead className='t-header'>
                    <tr>
                      <th className='custom-header'>Sl. No.</th>
                      <th className='custom-header'>Role</th>
                      <th className='custom-header'>Report To</th>
                      <th className='custom-header'>Status</th>
                      <th className='custom-header'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData.map((item, index) => (
                      <tr key={index}>
                        <td className="custom-data">{indexOfFirstItem + index + 1}</td>
                        <td className="custom-data">{getSafeValue(item.RoleName)}</td>
                        <td className="custom-data">{getSafeValue(item.ReportsTo)}</td>
                        <td className="custom-data">{getSafeValue(item.Status)}</td>
                        <td className="custom-data">
                          <span onClick={() => handleEdit(index)} style={{ cursor: 'pointer' }}>
                            <FaEdit />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container mb-3">
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                    <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                  </li>
                </ul>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                    <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ConfirmationPopup
        showConfirmation={showConfirmation}
        handleConfirmationClose={handleConfirmationClose}
        isUpdate={isUpdatePopup}
      />
      <Footer />
    </>
  );
};