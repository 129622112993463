import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { Passenger } from "./pages/Passenger";
import { Navbar } from "./pages/Navbar";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Entries } from "./pages/Entries";
import { UserRegistration } from "./pages/UserRegistration";
import { Checkpoint } from "./pages/Checkpoint";
import { RoleRegistration } from "./pages/RoleRegistration";
import { DeviceRegistration } from "./pages/DeviceRegistration";
import { Dummy } from "./pages/Dummy";
import { WelcomePage } from "./pages/WelcomePage";
import Unauthorized from "./pages/Unauthorized";
import ProtectedRoute from "./pages/ProtectedRoute";
import { CISFDetails } from "./pages/CISFDetails";
import { AirlineStaffDetails } from "./pages/AirlineStaffDetails";
import { AddItem } from "./pages/AddItem";
import { DeviceReport } from "./pages/DeviceReport";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/dummy" element={<Dummy />} />
        {/* <Route path="/cisf" element={<CISFDetails />} />
        <Route path="/airline" element={<AirlineStaffDetails />} /> */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute pageUrl="Dashboard" element={<Dashboard />} />}
        />
        {/* <Route
          path="/entries"
          element={<ProtectedRoute pageUrl="Add Baggage Details" element={<Entries />} />}
        /> */}
        <Route
          path="/passenger"
          element={<ProtectedRoute pageUrl="Pax/Baggage Summary" element={<Passenger />} />}
        />
        <Route
          path="/userRegistration"
          element={<ProtectedRoute pageUrl="Add User" element={<UserRegistration />} />}
        />
        <Route
          path="/checkpoint"
          element={<ProtectedRoute pageUrl="Add Checkpoint" element={<Checkpoint />} />}
        />
        <Route
          path="/roleRegistration"
          element={<ProtectedRoute pageUrl="Add Role" element={<RoleRegistration />} />}
        />
        <Route
          path="/deviceRegistration"
          element={<ProtectedRoute pageUrl="Add Device" element={<DeviceRegistration />} />}
        />
        <Route
          path="/item"
          element={<ProtectedRoute pageUrl="Add Prohibited Item" element={<AddItem />} />}
        />
        <Route
        path="/deviceReport"
        element={<ProtectedRoute pageUrl="Device Wise Report" element={<DeviceReport />} />}
      />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
