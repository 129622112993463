import React, { useState } from 'react';
import { Navbar } from './Navbar';
import LogoutTimer from './LogoutTimer';

export const WelcomePage = () => {

  const [permissions, setPermissions] = useState([]);
  // console.log(permissions);


  // useEffect(() => {
  //   const fetchPermissions = async () => {
  //     try {
  //       const response = await axios.get(`${Environment.BaseAPIURL}/GetPermissionListByUser?userid=${localStorage.getItem('username')}`);
  //       if (response.data && response.data.Data) {
  //         localStorage.setItem('permissions', JSON.stringify(response.data.Data));
  //         setPermissions(response.data.Data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching Permissions:', error);
  //     }
  //   };

  //   fetchPermissions();
  // }, []);

  // console.log('Array', permissions);

  return (
    <>
    <LogoutTimer />
      <Navbar permissions={permissions} />
      <main>
        <div className="backgroundOverlay-wel"></div>
        <div className='centerStyle-wel'>
          <h3>Welcome To Prohibited Baggage System</h3>
        </div>
      </main>
    </>
  );
};

