import React, { useState, useEffect } from 'react';
import { FaEdit, FaSearch } from "react-icons/fa";
import "./styles.css";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import Switch from 'react-switch';
import Environment from '../environment';
import axios from 'axios';
import Pagination from './Pagination';
import './Pagination.css';
import LogoutTimer from './LogoutTimer';
import secureLocalStorage from "react-secure-storage";


export const AddItem = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [Item, setItem] = useState('');
  const [status, setStatus] = useState('Active');
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [numberError, setNumberError] = useState('');
  const userName = secureLocalStorage.getItem('username');
  const [idx, setIdx] = useState('');
  const username = secureLocalStorage.getItem('username');
  const getSafeValue = (value) => {
    return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
  };

  const initialFormData = {
    Item: '',
    status: 'Active'
  };

  const [emptyFields, setEmptyFields] = useState({
    Item: false,
  });

  const [isUpdatePopup, setIsUpdatePopup] = useState(false);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false); // State for add/update confirmation
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State for delete confirmation
  const [deleteItem, setDeleteItem] = useState('');

  const ConfirmationPopup = ({ showConfirmation, handleConfirmationClose, isUpdate, Item }) => {
    return (
      showConfirmation && (
        <div>
          <div className="confirmation-overlay"></div>
          <div className="confirmation-popup">
            <h3 className='heading-popup'>{isUpdate ? 'Prohibited Item Updated successfully' : 'Prohibited Item Added successfully'}</h3>
            <div className="confirmation-buttons">
              <button
                onClick={() => handleConfirmationClose()}
                className="close-button"
              >Close</button>
            </div>
          </div>
        </div>
      )
    );
  };

  const DeletePopup = ({ handleDeleteConfirmed, handleConfirmationClose, Item, showConfirmation }) => {
    return (
      showConfirmation && (
        <div>
          <div className="confirmation-overlay"></div>
          <div className="confirmation-popup">
            <h5 className='heading-popup'>Do you want to delete this Prohibited Item: {Item}?</h5>
            <div className="confirmation-buttons">
              <button onClick={handleDeleteConfirmed} className="resets">Yes</button>
              <button onClick={handleConfirmationClose} className="adds">No</button>
            </div>
          </div>
        </div>
      )
    );
  };




  // Pagination Logic
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredData.length / pageSize);

  const [displayedData, setDisplayedData] = useState(filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  ));

  const indexOfFirstItem = currentPage * pageSize;
  const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    const startIndex = data.selected * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1);
    setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
  };


  const [formData, setFormData] = useState(initialFormData);
  const [editingIndex, setEditingIndex] = useState(null);
  const checkDuplicateItem = (e) => {
    return displayedData.some(role => role.ProhibitedItem === e);
  };

  // Inside handleFormSubmit function
  const handleFormSubmit = async (e, actionType) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const emptyFieldsCopy = { ...emptyFields };
    let hasEmptyField = false;

    if (!Item && actionType !== 'Delete') {
      emptyFieldsCopy.Item = true;
      hasEmptyField = true;
    }

    setEmptyFields(emptyFieldsCopy);

    if (hasEmptyField) {
      return;
    }

    if (actionType === 'Add' && checkDuplicateItem(Item)) {
      setNumberError('*Prohibited Item already exists.');
      return;
    }
    setNumberError('');

    const payload = {
      action: actionType,
      prohibitedItemDesc: actionType === 'Add' ? Item : deviceData[editingIndex].ProhibitedItemDesc,
      prohibitedItemDescNew: actionType === 'Update' ? Item : '',
      isActive: status === 'Active' ? 1 : 0,
      CreatedByUser: username
    };

    try {
      const response = await axios.post(`${Environment.BaseAPIURL}/AddUpdateProhibitedItemMaster`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.responseMessage === "Already exist") {
          setNumberError('*Prohibited Item already exists.');
          return;
        }
        const updatedResponse = await axios.get(`${Environment.BaseAPIURL}/GetAllProhibitedItemListDetail`);
        const updatedDeviceData = updatedResponse.data.map((item, index) => ({ ...item, originalIndex: index }));
        setDeviceData(updatedDeviceData);
        setFilteredData(updatedDeviceData);
        setShowAddConfirmation(true);
        setIsUpdatePopup(actionType === 'Update');
      } else {
        alert(`Failed to ${actionType.toLowerCase()} Prohibited Item. Please try again later.`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while processing your request. Please try again later.');
    }
    handleReset();
  };

  const handleDeleteConfirmed = async () => {
    const actualIndex = idx;
    console.log(actualIndex);
    const deleteItem = filteredData[actualIndex];

    if (deleteItem) {
      const token = localStorage.getItem('token');
      const payload = {
        action: 'Delete',
        prohibitedItemDesc: deleteItem.ProhibitedItemDesc,
        prohibitedItemDescNew: '',
        isActive: deleteItem.Status === 'Active' ? 1 : 0,
        CreatedByUser: "1"
      };

      try {
        const response = await axios.post(`${Environment.BaseAPIURL}/AddUpdateProhibitedItemMaster`, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          // Remove deleted item from filtered data
          const updatedData = filteredData.filter(item => item.ProhibitedItemDesc !== deleteItem.ProhibitedItemDesc);
          setFilteredData(updatedData);
          setShowDeleteConfirmation(false); // Close delete confirmation popup
        } else {
          alert('Failed to delete Prohibited Item. Please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while processing your request. Please try again later.');
      }
    } else {
      console.log('Error: Item to delete not found.');
    }
  };


  const handleDelete = (index) => {
    const actualIndex = currentPage * pageSize + index;
    setIdx(actualIndex);
    const deleteItem = filteredData[actualIndex];
    if (deleteItem) {
      setDeleteItem(deleteItem.ProhibitedItemDesc);
      setShowDeleteConfirmation(true);
    } else {
      console.log('Error: Item to delete not found.');
    }
  };
  const handleConfirmationClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleItemChange = (e) => {
    setItem(e.target.value);
    // Check for duplicate Checkpoint Name
    const isDuplicate = deviceData.some(data => String(data.ProhibitedItemDesc).toLowerCase() === e.target.value.toLowerCase());
    if (isDuplicate) {
      setNumberError('*Prohibited Item already exists.');
    } else {
      setNumberError('');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const deviceResponse = await axios.get(`${Environment.BaseAPIURL}/GetAllProhibitedItemListDetail`);
        const updatedDeviceData = deviceResponse.data.map((item, index) => ({ ...item, originalIndex: index }));
        console.log(updatedDeviceData);
        setDeviceData(updatedDeviceData);
        setFilteredData(updatedDeviceData);
        setDisplayedData(deviceResponse.data.slice(0, pageSize));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const handleEdit = (index) => {
    const actualIndex = currentPage * pageSize + index;
    const editedDevice = filteredData[actualIndex];

    if (editedDevice) {
      setItem(editedDevice.ProhibitedItemDesc);
      setStatus(editedDevice.Status);
      setEditingIndex(actualIndex);
      setEmptyFields({
        Item: false,
      });
    } else {
      console.log('Error: Edited Item not found.');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  const handleReset = () => {
    setItem('');
    setStatus('Active');
    setEditingIndex(null);
    setEmptyFields({
      Item: false,
    });
    setNumberError('');
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };
  const filterData = (searchTerm) => {
    const filtered = deviceData.filter((passenger) =>
      Object.values(passenger).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
    setDisplayedData(filtered.slice(0, pageSize));
  };


  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage, pageSize]);




  return (
    <>
      <LogoutTimer />
      <Navbar />
      <main className='backgroundStyle'>
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <h1 className='AllpagesHeaderTxt'>
                <img width="32" height="32" src="https://img.icons8.com/windows/32/emergency-exit.png" alt="" />
                Add Prohibited Item
              </h1>
            </div>
            <div className="col-md-12 col-xs-12 col-sm-12">
              <form className="custom-form row m-0" onSubmit={(e) => handleFormSubmit(e, editingIndex !== null ? 'Update' : 'Add')}>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.Item ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="24" height="24" src="https://img.icons8.com/windows/32/emergency-exit.png" alt="" /></span>
                    <input
                      className={`form-control ${emptyFields.Item ? 'has-error' : ''}`}
                      type="text"
                      value={Item}
                      placeholder="Enter Prohibited Item"
                      onChange={(e) => {
                        handleItemChange(e);
                        if (e.target.value !== '') {
                          setEmptyFields(prevState => ({ ...prevState, Item: false }));
                        }
                      }}
                    />
                  </div>
                  {numberError && <p className="error-message">{numberError}</p>}
                  {emptyFields.Item && <div className="error-message">*Prohibited Item is required</div>}
                </div>
                <div className='col-md-3 col-xs-2 col-sm-4 text-center'>
                  <div className='sub-buttons'>
                    {editingIndex !== null && (
                      <Switch
                        checked={status === 'Active'}
                        onChange={() => setStatus(status === 'Active' ? 'Inactive' : 'Active')}
                      />
                    )}
                    {editingIndex !== null ? (
                      <>
                        <button type="button" onClick={handleReset} className="reset">Reset</button>
                        <button type="submit" className="add">Update</button>
                      </>
                    ) : (
                      <>
                        <button type="button" onClick={handleReset} className="reset">Reset</button>
                        <button type="submit" className="add">Add</button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className='row mb-3'>
              <div className="col-md-12 col-sm-8 col-xs-12">
                <section className="scroller">
                  <div className="filter-box d-flex search-excel">
                    <div className="custom-input-group search-width" ><span className="input-group-text"><FaSearch /></span>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12 mb-3'>
              <div className="table-header mb-3">
                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                  setPageSize(parseInt(e.target.value));
                  setCurrentPage(0);
                }}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className='showing-span'>
                  Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                </span>
              </div>
              <div className="table-responsive">
                <table className="custom-table userEntriesTableSection">
                  <thead>
                    <tr>
                      <th className="custom-header">Sl.No.</th>
                      <th className='custom-header'>Prohibited Item</th>
                      <th className='custom-header'>Status</th>
                      <th className='custom-header'>Action</th>
                      <th className='custom-header'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData.map((data, index) => (
                      <tr key={data.id}>
                        <td className='custom-data'>{indexOfFirstItem + index + 1}</td>
                        <td className='custom-data'>{getSafeValue(data.ProhibitedItemDesc)}</td>
                        <td className='custom-data'>{getSafeValue(data.Status)}</td>
                        <td className='custom-data'>
                          <span onClick={() => handleEdit(index)} style={{ cursor: 'pointer' }}>
                            <FaEdit />
                          </span>
                        </td>
                        <td className='custom-data'>
                          <span onClick={() => handleDelete(index)} style={{ cursor: 'pointer' }}>
                            <img width="20" height="20" src="https://img.icons8.com/wired/64/filled-trash.png" alt="filled-trash" />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                    <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                  </li>
                </ul>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                    <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ConfirmationPopup
        showConfirmation={showAddConfirmation}
        handleConfirmationClose={() => setShowAddConfirmation(false)}
        isUpdate={isUpdatePopup}
        Item={Item}
      />
      <DeletePopup
        showConfirmation={showDeleteConfirmation}
        handleDeleteConfirmed={handleDeleteConfirmed}
        handleConfirmationClose={handleConfirmationClose}
        Item={deleteItem}
      />
      <Footer />
    </>
  );
};
