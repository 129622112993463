import { Bar } from "react-chartjs-2";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import "./style.css";
import { FaChartBar } from "react-icons/fa";
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Environment from '../environment';
import LogoutTimer from './LogoutTimer';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarController,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    BarController
);

export const Dashboard = () => {
    const [data, setData] = useState({
        labels: ["Total Baggages Today", "Total Baggages Yesterday", "Current Month", "Last Month"],
        datasets: [
            {
                backgroundColor: "#001B94",
                borderWidth: 1,
                data: [],
                label: "PESC HANDBAG ZONE"
            }
        ]
    });

    const [totalCounts, setTotalCounts] = useState({
        today: 0,
        yesterday: 0,
        currentMonth: 0,
        lastMonth: 0
    });

    const fetchDataForParameter = async (param) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${Environment.BaseAPIURL}/GetCountByCheckPointsForDashboard?Dateby=${param}`,{
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
            return response.data.countByCheckPointList;
        } catch (error) {
            console.error(`Error fetching data for ${param}:`, error);
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const todayData = await fetchDataForParameter("Today");
            const yesterdayData = await fetchDataForParameter("Yesterday");
            const currentMonthData = await fetchDataForParameter("Current");
            const lastMonthData = await fetchDataForParameter("Last");

            const aggregateCounts = (data) => data.reduce((acc, item) => acc + item.TotalCounts, 0);

            setTotalCounts({
                today: aggregateCounts(todayData),
                yesterday: aggregateCounts(yesterdayData),
                currentMonth: aggregateCounts(currentMonthData),
                lastMonth: aggregateCounts(lastMonthData)
            });

            const newData = {
                labels: ["Total Baggages Today", "Total Baggages Yesterday", "Current Month", "Last Month"],
                datasets: [
                    {
                        backgroundColor: ["#001B94", "#027BFF", "#001B94", "#027BFF"],
                        borderWidth: 1,
                        data: [
                            todayData.find(item => item.checkpointname === "PESC HANDBAG ZONE")?.TotalCounts || 0,
                            yesterdayData.find(item => item.checkpointname === "PESC HANDBAG ZONE")?.TotalCounts || 0,
                            currentMonthData.find(item => item.checkpointname === "PESC HANDBAG ZONE")?.TotalCounts || 0,
                            lastMonthData.find(item => item.checkpointname === "PESC HANDBAG ZONE")?.TotalCounts || 0
                        ],
                        label: "PESC Handbag Zone"
                    }
                ]
            };
            setData(newData);
        };
        fetchData();
    }, []);

    const options = {
        plugins: {
            legend: {
                display: true
            }
        },
        layout: {
            padding: {
                left: 5,
                right: 5,
                top: 10,
                bottom: 5
            },
            margin: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                border: { dash: [6, 6], display: true },
                grid: {
                    display: true
                },
                ticks: {
                    padding: 15
                }
            },
            x: {
                beginAtZero: true,
                border: { display: true },
                grid: {
                    display: false
                },
                ticks: {
                    padding: 7
                }
            }
        },
        elements: {
            bar: {
                borderWidth: 0.7
            }
        }
    };

    const [data2, setData2] = useState({
        labels: [],
        datasets: [
            {
                label: 'Prohibited Items',
                borderWidth: 0.8,
                data: [],
                backgroundColor: []
            }
        ]
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${Environment.BaseAPIURL}/GetCountByProhibitedItemsForDashboard`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
            .then(response => {
                const apiData = response.data;
                const labels = apiData.map(item => item.ProhibitedItem);
                const data = apiData.map(item => item.total_count);
                const backgroundColor = data.map((_, index) => index % 2 === 0 ? '#001B94' : '#027BFF');

                setData2({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Prohibited Items',
                            borderWidth: 0.8,
                            data: data,
                            backgroundColor: backgroundColor
                        }
                    ]
                });
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const options2 = {
        plugins: {
            legend: {
                display: true,
                backgroundColor: 'black'
            }
        },
        layout: {
            padding: {
                left: 5,
                right: 5,
                top: 10,
                bottom: 5
            },
            margin: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                border: { dash: [6, 6], display: true },
                grid: {
                    display: true
                },
                ticks: {
                    padding: 15
                }
            },
            x: {
                beginAtZero: true,
                border: { display: true },
                grid: {
                    display: false
                },
                ticks: {
                    padding: 7
                }
            }
        },
        elements: {
            bar: {
                borderWidth: 0.7
            }
        }
    };

    return (
        <>
        <LogoutTimer />
            <Navbar />
            <main className='backgroundStyle-dash'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                            <h1 className="AllpagesHeaderTxt">
                                <FaChartBar /> &nbsp;
                                Dashboard
                            </h1>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xs-3 mb-4">
                            <div className="tiles1 tiles">
                                <div className="tiles-icon">
                                    <h5 className="tiles-count text-white">{totalCounts.today}</h5>
                                    <span className="tiles-span"><img src="/sources/total-bag.png" alt="Logo" width="50" height="50" /></span>
                                </div>
                                <h5 className="tiles-heading text-white">Total Baggages Today</h5>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xs-3 mb-4">
                            <div className="tiles2 tiles">
                                <div className="tiles-icon">
                                    <h5 className="tiles-count text-white">{totalCounts.yesterday}</h5>
                                    <span className="tiles-span"><img width="50" height="50" src="/sources/t-bag-yest.png" alt="luggage--v1" /></span>
                                </div>
                                <h5 className="tiles-heading text-white">Total Baggages Yesterday</h5>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xs-3 mb-4">
                            <div className="tiles3 tiles">
                                <div className="tiles-icon">
                                    <h5 className="tiles-count text-white">{totalCounts.currentMonth}</h5>
                                    <span className="tiles-span"><img width="50" height="50" src="/sources/cur-month.png" alt="external-baggage-airport-icongeek26-glyph-icongeek26" /></span>
                                </div>
                                <h5 className="tiles-heading text-white">Total Baggages Current Month</h5>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-3 col-xs-3 mb-4">
                            <div className="tiles4 tiles">
                                <div className="tiles-icon">
                                    <h5 className="tiles-count text-white">{totalCounts.lastMonth}</h5>
                                    <span className="tiles-span"><img width="50" height="50" src="/sources/last-month.png" alt="external-baggage-airport-icongeek26-glyph-icongeek26-1" /></span>
                                </div>
                                <h5 className="tiles-heading text-white">Total Baggages Last Month</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <div className='row'>
                            <div className="col-sm-6 col-md-6 col-xs-6 mt-4 mb-2">
                                <div className="chart-container">
                                    <Bar data={data} options={options} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-xs-6 mt-4 mb-2">
                                <div className="chart-container">
                                    <Bar data={data2} options={options2} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default Dashboard;
