import React from "react";
import { FaFileAlt, FaChartBar, FaSignOutAlt, FaUserAlt} from "react-icons/fa";
import "./styles.css";
import secureLocalStorage from "react-secure-storage";

export const Navbar = () => {
  const permissions = JSON.parse(secureLocalStorage.getItem('permissions'));
  // console.log('Navbar permissions:', permissions);

  const hasPermission = (pageUrl) => {
    return permissions && permissions.some(permission => permission.PageUrl === pageUrl && permission.IsActive);
  };

  const hasAnyPermission = (pageUrls) => {
    return pageUrls.some(url => hasPermission(url));
  };

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <>
      <nav className="nav-stickyness navbar navbar-expand-lg navbar-expand-sm navbar-expand-xs sticky-top custom-navbar-bg">
        <div className="container-fluid custom-container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <div className="Loginnavbar-brand">
              <span><img src="/sources/logo2.png" alt="Logo" width="50" height="50" /></span>
            </div>
            <ul className="navbar-nav me-auto">
              {hasPermission("Dashboard") && (
                <li className="nav-item">
                  <a className="nav-link mr-0" aria-current="page" href="/dashboard"><FaChartBar /> Dashboard</a>
                </li>
              )}
              {hasAnyPermission(["Add Role", "Add User", "Add Device", "Add Checkpoint", "Add Prohibited Item"]) && (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FaUserAlt /> Administration
                  </a>
                  <ul className="dropdown-menu drop-eidted">
                    {hasPermission("Add Role") && (
                      <li><a className="dropdown-item item-edited" href="/roleRegistration"><img width="22" height="22" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/group-foreground-selected.png" alt="" /> Add Role</a></li>
                    )}
                    {hasPermission("Add User") && (
                      <li><a className="dropdown-item item-edited" href="/userRegistration"><img width="22" height="22" src="https://img.icons8.com/material-sharp/24/FFFFFF/user.png" alt="user" /> Add User</a></li>
                    )}
                    {hasPermission("Add Device") && (
                      <li><a className="dropdown-item item-edited" href="/deviceRegistration"><img width="22" height="22" src="https://img.icons8.com/dotty/80/FFFFFF/multiple-smartphones.png" alt="multiple-smartphones" /> Add Device</a></li>
                    )}
                    {hasPermission("Add Checkpoint") && (
                      <li><a className="dropdown-item item-edited" href="/checkpoint"><img width="22" height="22" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-checkpoint-database-outline-outline-black-m-oki-orlando.png" alt="" /> Add Checkpoint</a></li>
                    )}
                    {hasPermission("Add Prohibited Item") && (
                      <li><a className="dropdown-item item-edited" href="/item"><img width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/no-smoking.png" alt="no-smoking"/> Add Prohibited Item</a></li>
                    )}
                    {/* <li><a className="dropdown-item item-edited" href="/cisf"><img width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/soldier-man.png" alt="soldier-man"/> Add CISF Staff Details</a></li>
                    <li><a className="dropdown-item item-edited" href="/airline"><img width="20" height="20" src="https://img.icons8.com/ios/50/FFFFFF/administrator-male--v1.png" alt=""/> Add Airline Staff Details</a></li> */}
                  </ul>
                </li>
              )}
              {/* {hasAnyPermission(["Add Baggage Details"]) && (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FaCogs /> Operations
                  </a>
                  <ul className="dropdown-menu drop-eidted">
                    {hasPermission("Add Baggage Details") && (
                      <li><a className="dropdown-item item-edited" href="/entries"><FaList /> Add Baggage Details</a></li>
                    )}
                  </ul>
                </li>
              )} */}
              {hasAnyPermission(["Pax/Baggage Summary", "Device Wise Report"]) && (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img width="19" height="19" src="https://img.icons8.com/material-rounded/24/FFFFFF/business-report.png" alt="business-report" /> Reports
                  </a>
                  <ul className="dropdown-menu drop-eidted">
                    {hasPermission("Pax/Baggage Summary") && (
                      <li><a className="dropdown-item item-edited" href="/passenger"><FaFileAlt /> Pax/Baggage Summary</a></li>
                    )}
                    {hasPermission("Device Wise Report") && (
                      <li><a className="dropdown-item item-edited" href="/deviceReport"><img width="20" height="20" src="https://img.icons8.com/windows/32/FFFFFF/nook.png" alt="device" /> Device Wise Summary</a></li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
            <form className="d-flex mt-0" role="search">
              <a className="nav-link" aria-current="page" href="/" onClick={handleLogout}><FaSignOutAlt /> Logout</a>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};