import React from "react";
import { Navigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const ProtectedRoute = ({ element: Component, pageUrl }) => {
  const permissions = JSON.parse(secureLocalStorage.getItem('permissions'));

  const hasPermission = (pageUrl) => {
    return permissions && permissions.some(permission => permission.PageUrl === pageUrl && permission.IsActive);
  };

  return hasPermission(pageUrl) ? Component : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;