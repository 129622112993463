import React, { useState, useEffect, useRef } from 'react';
import { FaEdit, FaSearch, FaSearchLocation } from "react-icons/fa";
import "./styles.css";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import Switch from 'react-switch';
import Environment from '../environment';
import axios from 'axios';
import Pagination from './Pagination';
import './Pagination.css';
import LogoutTimer from './LogoutTimer';
import secureLocalStorage from 'react-secure-storage';


export const Checkpoint = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [Alias, setAlias] = useState('');
  const [status, setStatus] = useState('Active');
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [numberError, setNumberError] = useState('');
  const username = secureLocalStorage.getItem('username');
  const getSafeValue = (value) => {
    return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
  };

  const initialFormData = {
    checkpoint: '',
    Alias: '',
    status: 'Active'
  };

  const [emptyFields, setEmptyFields] = useState({
    checkpoint: false,
    Alias: false,
  });

  const [isUpdatePopup, setIsUpdatePopup] = useState(false);

  const ConfirmationPopup = ({ showConfirmation, handleConfirmationClose, isUpdate }) => {
    return (
      showConfirmation && (
        <div>
          <div className="confirmation-overlay"></div>
          <div className="confirmation-popup">
            <h3 className='heading-popup'>{isUpdate ? 'Checkpoint Updated successfully' : 'Checkpoint Added successfully'}</h3>
            <button
              onClick={handleConfirmationClose}
              type="submit"
              className="close-button"
            >Close</button>
          </div>
        </div>
      )
    );
  };



  // Pagination Logic
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredData.length / pageSize);

  const [displayedData, setDisplayedData] = useState(filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  ));

  const indexOfFirstItem = currentPage * pageSize;
  const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    const startIndex = data.selected * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1);
    setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const [formData, setFormData] = useState(initialFormData);
  const [checkpoint, setcheckpoint] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const checkDuplicateCheckpoint = (roleName) => {
    return displayedData.some(role => role.RoleName === roleName);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const emptyFieldsCopy = { ...emptyFields };
    let hasEmptyField = false;

    // Check for empty fields
    if (!checkpoint) {
      emptyFieldsCopy.checkpoint = true;
      hasEmptyField = true;
    }
    if (!Alias) {
      emptyFieldsCopy.Alias = true;
      hasEmptyField = true;
    }

    setEmptyFields(emptyFieldsCopy);

    if (hasEmptyField) {
      return;
    }

    if (editingIndex === null && checkDuplicateCheckpoint(checkpoint)) {
      setNumberError('*Checkpoint already exists.');
      return;
    }
    setNumberError('');

    try {
      const response = await fetch(`${Environment.BaseAPIURL}/AddUpdateCheckPointName`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
              action: editingIndex !== null ? 'Update' : 'Add',
              checkPointName: checkpoint,
              terminal: 1,
              aliasName: Alias,
              isActive: status === 'Active' ? 1 : 0,
              CreatedByUser: username
          })
      });
  
      console.log('Post response:', response);
  
      if (response.ok) {
          const data = await response.json();
          console.log('Response data:', data);
  
          if (data.response === "Checkpoint exist") {
              setNumberError('*Checkpoint already exists.');
              return;
          }
  
          const updatedResponse = await axios.get(`${Environment.BaseAPIURL}/GetCheckPointName`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
  
          const updatedDeviceData = updatedResponse.data.map((item, index) => ({ ...item, originalIndex: index }));
          setDeviceData(updatedDeviceData);
          setFilteredData(updatedDeviceData);
          setFormData(initialFormData);
          setShowConfirmation(true);
          setIsUpdatePopup(editingIndex !== null);
      } else {
          alert('Failed to add/update checkpoint. Please try again later.');
      }
  } catch (error) {
      console.error('Error:', error); // Log any errors
      alert('An error occurred while processing your request. Please try again later.');
  }
  
    handleReset();
  };

  const handleCheckpointChange = (e) => {
    setcheckpoint(e.target.value);
    const isDuplicate = deviceData.some(data => data.CheckPointName.toLowerCase() === e.target.value.toLowerCase());
    if (isDuplicate) {
      setNumberError('*Checkpoint already exists.');
    } else {
      setNumberError('');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      try {
        const deviceResponse = await axios.get(`${Environment.BaseAPIURL}/GetCheckPointName`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const updatedDeviceData = deviceResponse.data.map((item, index) => ({ ...item, originalIndex: index }));
        setDeviceData(updatedDeviceData);
        setFilteredData(updatedDeviceData);
        setDisplayedData(deviceResponse.data.slice(0, pageSize));
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response) {
        } else if (error.request) {
          console.error('Request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    };

    fetchData();
  }, []);

  const handleEdit = (index) => {
    const actualIndex = currentPage * pageSize + index;
    const editedDevice = filteredData[actualIndex];

    if (editedDevice) {
      setAlias(editedDevice.AliasName);
      setcheckpoint(editedDevice.CheckPointName);
      setStatus(editedDevice.Status);
      setEditingIndex(actualIndex);
      setEmptyFields({
        checkpoint: false,
        Alias: false,
      });
    } else {
      console.log('Error: Edited device not found.');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  const handleReset = () => {
    setAlias('');
    setcheckpoint('');
    setStatus('Active');
    setEditingIndex(null);
    setEmptyFields({
      checkpoint: false,
      Alias: false,
    });
    setNumberError('');
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };
  const filterData = (searchTerm) => {
    const filtered = deviceData.filter((passenger) =>
      Object.values(passenger).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
    setDisplayedData(filtered.slice(0, pageSize));
  };


  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage, pageSize]);




  return (
    <>
    <LogoutTimer />
      <Navbar />
      <main className='backgroundStyle'>
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <h1 className='AllpagesHeaderTxt'>
                <img width="32" height="32" src="https://img.icons8.com/windows/32/tollbooth.png" alt="" />
                Checkpoint
              </h1>
            </div>
            <div className="col-md-12 col-xs-12 col-sm-12">
              <form className="custom-form row m-0" onSubmit={handleFormSubmit}>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.checkpoint ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="24" height="24" src="/sources/checkpoint.png" alt="" /></span>
                    {/* <input
                      className="form-control"
                      type="text"
                      value={checkpoint}
                      placeholder="Checkpoint Name"
                      onChange={handleCheckpointChange}
                    disabled={editingIndex !== null}
                    /> */}
                    <input
                      className={`form-control ${emptyFields.checkpoint ? 'has-error' : ''}`}
                      type="text"
                      value={checkpoint}
                      placeholder="Checkpoint Name"
                      onChange={(e) => {
                        handleCheckpointChange(e);
                        if (e.target.value !== '') {
                          setEmptyFields(prevState => ({ ...prevState, checkpoint: false }));
                        }
                      }}
                      disabled={editingIndex !== null}
                    />
                  </div>
                  {numberError && <p className="error-message">{numberError}</p>}
                  {emptyFields.checkpoint && <div className="error-message">*Checkpoint Name is required</div>}
                </div>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.Alias ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="24" height="24" src="https://img.icons8.com/ios-filled/50/marker.png" alt="marker" /></span>
                    <input
                      className={`form-control ${emptyFields.Alias ? 'has-error' : ''}`}
                      type="text"
                      value={Alias}
                      placeholder="Location Name"
                      onChange={(e) => {
                        setAlias(e.target.value);
                        if (e.target.value !== '') {
                          setEmptyFields(prevState => ({ ...prevState, Alias: false }));
                        }
                      }}
                    />
                  </div>
                  {emptyFields.Alias && <div className="error-message">*Location Name is required</div>}
                </div>
                <div className='col-md-3 col-xs-2 col-sm-4 text-center'>
                  <div className='sub-buttons justify-content-xs-center'>
                    {editingIndex !== null && (
                      <Switch
                        checked={status === 'Active'}
                        onChange={() => setStatus(status === 'Active' ? 'Inactive' : 'Active')}
                      />
                    )}
                    {editingIndex !== null ? (
                      <>
                        <button type="button" onClick={handleReset} className=" reset">Reset</button>
                        <button type="submit" className="add">Update</button>
                      </>
                    ) : (
                      <>
                        <button type="button" onClick={handleReset} className=" reset">Reset</button>
                        <button type="submit" className="add">Add</button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className='row mb-3'>
              <div className="col-md-12 col-sm-8 col-xs-12">
                <section className="scroller">
                  <div className="filter-box d-flex search-excel justify-content-xs-center">
                    <div className="custom-input-group" style={{width: 300, marginRight: 0}}><span className="input-group-text"><FaSearch /></span>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className="table-header mb-3">
                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                  setPageSize(parseInt(e.target.value));
                  setCurrentPage(0);
                }}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className='showing-span'>
                  Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                </span>
              </div>
              <div className="table-responsive"style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="custom-table userEntriesTableSection">
                  <thead className='t-header'>
                    <tr>
                      <th className="custom-header">Sl.No.</th>
                      <th className='custom-header'>Checkpoint Name</th>
                      <th className='custom-header'>Location Name</th>
                      <th className='custom-header'>Status</th>
                      <th className='custom-header'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData.map((data, index) => (
                      <tr key={data.id}>
                      <td className='custom-data'>{indexOfFirstItem + index + 1}</td>
                      <td className='custom-data'>{getSafeValue(data.CheckPointName)}</td>
                      <td className='custom-data'>{getSafeValue(data.AliasName)}</td>
                      <td className='custom-data'>{getSafeValue(data.Status)}</td>
                      <td className='custom-data'>
                        <span onClick={() => handleEdit(index)} style={{ cursor: 'pointer' }}>
                          <FaEdit />
                        </span>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                    <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                  </li>
                </ul>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                    <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ConfirmationPopup
        showConfirmation={showConfirmation}
        handleConfirmationClose={handleConfirmationClose}
        isUpdate={isUpdatePopup}
      />
      <Footer />
    </>
  );
};