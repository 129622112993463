import React, { useState, useEffect } from 'react';
import { FaUser, FaEdit, FaSearch } from "react-icons/fa";
import Switch from 'react-switch';
import "./style.css";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import axios from 'axios';
import Environment from '../environment';
import Pagination from './Pagination';
import './Pagination.css';
import LogoutTimer from './LogoutTimer';
import secureLocalStorage from 'react-secure-storage';

export const UserRegistration = () => {
  const [isUpdatePopup, setIsUpdatePopup] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [AppPermission, setAppPermission] = useState('');
  const [Email, setEmail] = useState('');
  const [Contact, setContact] = useState('');
  const [UId, setUId] = useState('');
  const [UserName, setUserName] = useState('');
  const [role, setrole] = useState('');
  const [status, setStatus] = useState('Active');
  const [editingIndex, setEditingIndex] = useState(null);
  const [RoleList, setRoleList] = useState([]);
  const [ApplicationList, setApplicationList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [numberError, setNumberError] = useState('');
  const [validError, setValidError] = useState('');
  const token = localStorage.getItem('token');
  const username = secureLocalStorage.getItem('username');
  const getSafeValue = (value) => {
    return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
  };

  const initialFormData = {
    UId: '',
    Email: '',
    UserName: '',
    Contact: '',
    AppPermission: '',
    role: '',
    status: 'Active'
  };

  const [emptyFields, setEmptyFields] = useState({
    UId: false,
    Email: false,
    UserName: false,
    Contact: false,
    AppPermission: false,
    role: false
  });

  const [deviceData, setDeviceData] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredData.length / pageSize);
  const [displayedData, setDisplayedData] = useState(filteredData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  ));

  const indexOfFirstItem = currentPage * pageSize;
  const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    const startIndex = data.selected * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(0);
    setDisplayedData(filteredData.slice(0, pageSize));
  };

  const handleLastPageClick = () => {
    setCurrentPage(pageCount - 1);
    setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
  };

  const checkDuplicateUserId = (uid) => {
    return deviceData.some(user => String(user.UID).toLowerCase() === uid.toLowerCase());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const emptyFieldsCopy = { ...emptyFields };
    let hasEmptyField = false;

    // Check for empty fields
    if (!UId) {
        emptyFieldsCopy.UId = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.UId = false;
    }
    if (!UserName) {
        emptyFieldsCopy.UserName = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.UserName = false;
    }
    if (!Email) {
        emptyFieldsCopy.Email = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.Email = false;
    }
    if (!Contact) {
        emptyFieldsCopy.Contact = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.Contact = false;
    }
    if (!role) {
        emptyFieldsCopy.role = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.role = false;
    }
    if (!AppPermission) {
        emptyFieldsCopy.AppPermission = true;
        hasEmptyField = true;
    } else {
        emptyFieldsCopy.AppPermission = false;
    }

    setEmptyFields(emptyFieldsCopy);

    if (hasEmptyField) {
        return;
    }
    if (editingIndex === null && checkDuplicateUserId(UId)) {
        setNumberError('*User already exists.');
        return;
    }

    setNumberError('');

    try {
        const response = await axios.post(`${Environment.BaseAPIURL}/AddUpdateUserDetail`, {
            action: editingIndex !== null ? 'Update' : 'Add',
            uid: UId,
            userName: UserName,
            email: Email,
            isActive: status === 'Active' ? 1 : 0,
            CreatedByUser: username,
            contract: Contact,
            roleName: role,
            appPermission: AppPermission
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.status === 200) {
        const data = response.data;
        if (data.response === "User exist") {
          setNumberError('*User already exists.');
          return;
        }
        const updatedResponse = await axios.get(`${Environment.BaseAPIURL}/GetUserDetails`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const updatedDeviceData = updatedResponse.data;
        setDeviceData(updatedDeviceData);
        setDisplayedData(updatedResponse.data);
        setFilteredData(updatedResponse.data);
        setFormData(initialFormData);
        setShowConfirmation(true);
        setIsUpdatePopup(editingIndex !== null);
      } else {
        alert('Failed to add/update Role. Please try again later.');
      }
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        alert('An error occurred while processing your request. Please try again later.');
    }
    handleReset();
};



  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      try {
        const [deviceResponse, roleResponse, appResponse] = await Promise.all([
          axios.get(`${Environment.BaseAPIURL}/GetUserDetails`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }),
          axios.get(`${Environment.BaseAPIURL}/GetRoleList`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }),
          axios.get(`${Environment.BaseAPIURL}/GetPortalDeviceValues`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
        ]);
        setDeviceData(deviceResponse.data);
        setRoleList(roleResponse.data);
        setFilteredData(deviceResponse.data);
        setApplicationList(appResponse.data);
        setDisplayedData(deviceResponse.data.slice(0, pageSize));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (index) => {
    const actualIndex = currentPage * pageSize + index;
    const editedDevice = displayedData[index];

    if (editedDevice) {

      setUId(editedDevice.UID);
      setUserName(editedDevice.UserName);
      setEmail(editedDevice.Email);
      setContact(editedDevice.Contact);
      setrole(editedDevice.RoleName);
      setAppPermission(editedDevice.AppPermission);
      setStatus(editedDevice.Status);
      setEditingIndex(actualIndex);
      setEmptyFields({
        UID: false,
        Email: false,
        UserName: false,
        Contact: false,
        AppPermission: false,
        role: false
      });
    } else {
      console.error('Error: Edited device not found.');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleReset = () => {
    setUserName('');
    setUId('');
    setEmail('');
    setContact('');
    setAppPermission('');
    setrole('');
    setStatus('Active');
    setNumberError('');
    setEmptyFields({
      UId: false,
      Email: false,
      UserName: false,
      Contact: false,
      AppPermission: false,
      role: false
    });
    setEditingIndex(null);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (searchTerm) => {
    const filtered = deviceData.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setCurrentPage(0);
    setDisplayedData(filtered.slice(0, pageSize));
  };

  const ConfirmationPopup = ({ showConfirmation, handleConfirmationClose, isUpdate }) => {
    return (
      showConfirmation && (
        <div>
          <div className="confirmation-overlay"></div>
          <div className="confirmation-popup">
            <h3 className='heading-popup'>{isUpdate ? 'User Updated successfully' : 'User Added successfully'}</h3>
            <button
              onClick={handleConfirmationClose}
              type="submit"
              className="close-button"
            >Close</button>
          </div>
        </div>
      )
    );
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedData(filteredData.slice(startIndex, endIndex));
  }, [filteredData, currentPage, pageSize]);




  return (
    <>
    <LogoutTimer />
      <Navbar />
      <main className='backgroundStyle'>
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <h1 className='AllpagesHeaderTxt'>
                <img width="38" height="38" src="/sources/pass-data.png" alt="person-male--v3" />
                Add User
              </h1>
            </div>
            <div className="col-md-12 col-xs-12 col-sm-12">
              <form className="custom-form row m-0" onSubmit={handleFormSubmit}>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.UId ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/windows/32/id-verified.png" alt="id-verified" /></span>
                    <input className="form-control"
                      type="text"
                      value={UId}
                      placeholder="UserID"
                      onChange={(e) => {
                        setUId(e.target.value);
                        if (e.target.value !== '') {
                          setEmptyFields(prevState => ({ ...prevState, UId: false }));
                        };
                        setNumberError('');
                        if (checkDuplicateUserId(e.target.value)) {
                          setNumberError('*User already exists.');
                        }
                      }}
                      disabled={editingIndex !== null} />
                  </div>
                  {emptyFields.UId && <div className="error-message">*UserID is required</div>}
                  {numberError && <p className="error-message">{numberError}</p>}
                </div>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.UserName ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><FaUser /></span>
                    <input className="form-control" type="text" value={UserName} placeholder="Name" onChange={(e) => {
                      setUserName(e.target.value);
                      if (e.target.value !== '') {
                        setEmptyFields(prevState => ({ ...prevState, UserName: false }));
                      }
                    }} />
                  </div>
                  {emptyFields.UserName && <div className="error-message">*Name is required</div>}
                </div>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.Email ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/ios-glyphs/30/new-post.png" alt="new-post" /></span>
                    <input className="form-control" type="email" value={Email} placeholder="Email" onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value !== '') {
                        setEmptyFields(prevState => ({ ...prevState, Email: false }));
                      };
                    }} />
                  </div>
                  {emptyFields.Email && <div className="error-message">*Email is required</div>}
                </div>
                <div className={`col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.Contact ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/ios-filled/50/phone.png" alt="phone" /></span>
                    <input
                      className="form-control"
                      name="contact"
                      type="text"
                      value={Contact}
                      placeholder="Contact"
                      maxLength={10}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          setContact(value);
                          if (value !== '') {
                            setEmptyFields(prevState => ({ ...prevState, Contact: false }));
                          }
                        }
                      }}
                    />
                  </div>
                  {emptyFields.Contact && <div className="error-message">*Contact is required</div>}
                </div>
                <div className={`dropdown col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.role ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/ios-glyphs/30/group-foreground-selected.png" alt="administrator-male--v1" /></span>
                    <select className='drop-item form-control form-select sel-items' value={role} onChange={(e) => {
                      setrole(e.target.value);
                      if (e.target.value !== '') {
                        setEmptyFields(prevState => ({ ...prevState, role: false }));
                      };
                    }}>
                      <option value="" disabled>Role</option>
                      {RoleList.map((roleItem, index) => (
                        <option key={index} value={roleItem.RoleName}>{roleItem.RoleName}</option>
                      ))}
                    </select>
                  </div>
                  {emptyFields.role && <p className="error-message">*Role is required</p>}
                </div>
                <div className={`dropdown col-md-3 col-sm-4 col-xs-12 mb-4 ${emptyFields.AppPermission ? 'has-error' : ''}`}>
                  <div className="input-group">
                    <span className="input-group-text"><img width="22" height="22" src="https://img.icons8.com/material-outlined/24/application-form.png" alt="application-form" /></span>
                    <select className='drop-item form-control form-select sel-items' value={AppPermission} onChange={(e) => {
                      setAppPermission(e.target.value);
                      if (e.target.value !== '') {
                        setEmptyFields(prevState => ({ ...prevState, AppPermission: false }));
                      };
                    }}>
                      <option value="" disabled>Select Application</option>
                      {ApplicationList.map((appItem, index) => (
                        <option key={index} value={appItem.PortalDeviceValues}>{appItem.PortalDeviceValues}</option>
                      ))}
                    </select>
                  </div>
                  {emptyFields.AppPermission && <p className="error-message">*Application is required</p>}
                </div>
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <div className='sub-buttons'>
                    {editingIndex !== null && (
                      // Switch toggle button
                      <Switch
                        checked={status === 'Active'}
                        onChange={() => setStatus(status === 'Active' ? 'Inactive' : 'Active')}
                      />
                    )}
                    {editingIndex !== null ? (
                      <>
                        <button type="button" onClick={handleReset} className="reset">Reset</button>
                        <button type="submit" className="add">Update</button>
                      </>
                    ) : (
                      <>
                        <button type="button" onClick={handleReset} className="reset">Reset</button>
                        <button type="submit" className="add">Add</button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className='row mb-3'>
              <div className="col-md-12 col-sm-8 col-xs-12">
                <section className="scroller">
                  <div className="filter-box d-flex search-excel">
                    <div className="custom-input-group" style={{ width: 300, marginRight: 0 }}><span className="input-group-text"><FaSearch /></span>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 col-xs-12 mb-3'>
              <div className="table-header mb-3">
                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                  setPageSize(parseInt(e.target.value));
                  setCurrentPage(0);
                }}>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <span className='showing-span'>
                  Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                </span>
              </div>
              <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="custom-table userEntriesTableSection">
                  <thead className='t-header'>
                    <tr>
                      <th className="custom-header">Sl.No.</th>
                      <th className='custom-header'>UserID</th>
                      <th className='custom-header'>Name</th>
                      <th className='custom-header'>Email</th>
                      <th className='custom-header'>Contact</th>
                      <th className='custom-header'>Role</th>
                      <th className='custom-header'>Application</th>
                      <th className='custom-header'>Status</th>
                      <th className='custom-header'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedData.map((data, index) => (
                      <tr key={data.id}>
                      <td className='custom-data'>{indexOfFirstItem + index + 1}</td>
                      <td className='custom-data'>{getSafeValue(data.UID)}</td>
                      <td className='custom-data'>{getSafeValue(data.UserName)}</td>
                      <td className='custom-data'>{getSafeValue(data.Email)}</td>
                      <td className='custom-data'>{getSafeValue(data.Contact)}</td>
                      <td className='custom-data'>{getSafeValue(data.RoleName)}</td>
                      <td className='custom-data'>{getSafeValue(data.AppPermission)}</td>
                      <td className='custom-data'>{getSafeValue(data.Status)}</td>
                      <td className='custom-data'>
                        <span onClick={() => handleEdit(index)} style={{ cursor: 'pointer' }}>
                          <FaEdit />
                        </span>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                    <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                  </li>
                </ul>
                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                <ul className="pagination" role="navigation" aria-label="Pagination">
                  <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                    <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ConfirmationPopup
        showConfirmation={showConfirmation}
        handleConfirmationClose={handleConfirmationClose}
        isUpdate={isUpdatePopup}
      />
      <Footer />
    </>
  );
};

