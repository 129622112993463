import React, { useState, useEffect } from 'react';
import { FaUser, FaHashtag, FaEdit, FaSearch } from "react-icons/fa";
import "./style.css";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import Switch from 'react-switch';
import axios from 'axios';
import Environment from '../environment';
import Pagination from './Pagination';
import 'react-toastify/dist/ReactToastify.css';
import './Pagination.css';
import LogoutTimer from './LogoutTimer';
import secureLocalStorage from 'react-secure-storage';

export const DeviceRegistration = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isUpdatePopup, setIsUpdatePopup] = useState(false);
    const initialFormData = {
        checkpoint: '',
        number: '',
        name: '',
        status: 'Active'
    };
    const [deviceData, setDeviceData] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [checkpoint, setCheckpoint] = useState('');
    const [status, setStatus] = useState('Active');
    const [editingIndex, setEditingIndex] = useState(null);
    const [checkpointList, setCheckpointList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [numberError, setNumberError] = useState('');
    const username = secureLocalStorage.getItem('username');
    const getSafeValue = (value) => {
        return value && typeof value === 'object' ? JSON.stringify(value) : value || '';
    };

    const [emptyFields, setEmptyFields] = useState({
        checkpoint: false,
        number: false,
        name: false
    });

    // Pagination
    const [currentPage, setCurrentPage] = useState(0);
    const pageCount = Math.ceil(filteredData.length / pageSize);

    const [displayedData, setDisplayedData] = useState(filteredData.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
    ));

    const indexOfFirstItem = currentPage * pageSize;
    const indexOfLastItem = Math.min(indexOfFirstItem + pageSize, filteredData.length);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        const startIndex = data.selected * pageSize;
        const endIndex = startIndex + pageSize;
        setDisplayedData(filteredData.slice(startIndex, endIndex));
    };

    const handleFirstPageClick = () => {
        setCurrentPage(0);
        setDisplayedData(filteredData.slice(0, pageSize));
    };

    const handleLastPageClick = () => {
        setCurrentPage(pageCount - 1);
        setDisplayedData(filteredData.slice((pageCount - 1) * pageSize, pageCount * pageSize));
    };

    const checkDuplicateDeviceNumber = (deviceNumber) => {
        return deviceData.some(device => String(device.DeviceNumber).toLowerCase() === deviceNumber.toLowerCase());
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const emptyFieldsCopy = { ...emptyFields };
        let hasEmptyField = false;

        // Check for empty fields
        if (!number) {
            emptyFieldsCopy.number = true;
            hasEmptyField = true;
        }
        if (!name) {
            emptyFieldsCopy.name = true;
            hasEmptyField = true;
        }
        if (!checkpoint) {
            emptyFieldsCopy.checkpoint = true;
            hasEmptyField = true;
        }

        setEmptyFields(emptyFieldsCopy);

        if (hasEmptyField) {
            setError("Please fill in all fields.");
            return;
        }

        // Check for duplicate device number
        if (checkDuplicateDeviceNumber(number) && editingIndex === null) {
            setNumberError('*Device already exists.');
            return;
        }

        // Reset specific error message
        setNumberError('');

        try {
            const response = await axios.post(`${Environment.BaseAPIURL}/AddUpdateDeviceRegistration`, {
                action: editingIndex !== null ? 'Update' : 'Add',
                deviceId: number,
                checkPointLocationName: checkpoint,
                deviceLocalName: name,
                isActive: status === 'Active' ? 1 : 0,
                CreatedByUser: username
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });    
            if (response.status === 200) {
                const data = response.data;
                if (data.response === "Device already exist.") {
                    setNumberError('*Device already exists.');
                    return;
                }
                const updatedResponse = await axios.get(`${Environment.BaseAPIURL}/GetDeviceDetail`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });                
                const updatedDeviceData = updatedResponse.data;
                setDeviceData(updatedDeviceData);
                setFilteredData(updatedDeviceData.map((device, index) => ({ ...device, originalIndex: index })));
                setFormData(initialFormData);
                setShowConfirmation(true);
                setIsUpdatePopup(editingIndex !== null);
            } else {
                alert('Failed to add/update Role. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
        handleReset();
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        setNumber(value);

        if (checkDuplicateDeviceNumber(value)) {
            setNumberError('*Device already exists.');
        } else {
            setNumberError('');
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchData = async () => {
            try {
                console.log("Fetching data...");

                const [deviceResponse, checkpointResponse] = await Promise.all([
                    axios.get(`${Environment.BaseAPIURL}/GetDeviceDetail`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }),
                    axios.get(`${Environment.BaseAPIURL}/GetCheckPointNameList`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                ]);
                setCheckpointList(checkpointResponse.data);
                setDeviceData(deviceResponse.data);
                setFilteredData(deviceResponse.data);
                console.log("hello");
                setDisplayedData(deviceResponse.slice(0, pageSize));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const ConfirmationPopup = ({ showConfirmation, handleConfirmationClose, isUpdate }) => {
        return (
            showConfirmation && (
                <div>
                    <div className="confirmation-overlay"></div>
                    <div className="confirmation-popup">
                        <h3 className='heading-popup'>{isUpdate ? 'Device Updated successfully' : 'Device Added successfully'}</h3>
                        <button
                            onClick={handleConfirmationClose}
                            type="submit"
                            className="close-button"
                        >Close</button>
                    </div>
                </div>
            )
        );
    };

    const handleEdit = (index) => {
        const actualIndex = currentPage * pageSize + index;
        const editedDevice = filteredData[actualIndex];

        if (editedDevice) {
            setCheckpoint(editedDevice.CheckPointName);
            setNumber(editedDevice.DeviceNumber);
            setName(editedDevice["Device Local Name"]);
            setStatus(editedDevice.Status);
            setEditingIndex(actualIndex);

            setEmptyFields({
                checkpoint: false,
                number: false,
                name: false
            });
        } else {
            console.log('Error: Edited device not found.');
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    };

    const handleReset = () => {
        setCheckpoint('');
        setNumber('');
        setName('');
        setStatus('Active');
        setEditingIndex(null);
        setEmptyFields({
            checkpoint: false,
            number: false,
            name: false
        });
        setNumberError('');
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        filterData(searchTerm);
    };

    const filterData = (searchTerm) => {
        const filtered = deviceData.filter((device) =>
            Object.values(device).some(
                (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredData(filtered);
        setCurrentPage(0);
        setDisplayedData(filtered.slice(0, pageSize));
    };

    const handleConfirmationClose = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        const startIndex = currentPage * pageSize;
        const endIndex = startIndex + pageSize;
        setDisplayedData(filteredData.slice(startIndex, endIndex));
    }, [filteredData, currentPage, pageSize]);

    return (
        <>
            <LogoutTimer />
            <Navbar />
            <main className='backgroundStyle'>
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                            <h1 className='AllpagesHeaderTxt'>
                                <img width="35" height="35" src="https://img.icons8.com/ios-filled/50/cell-phone.png" alt="cell-phone" />
                                Device Registration
                            </h1>
                        </div>
                        <div className="col-md-12 col-xs-12 col-sm-12">
                            <form className="custom-form row m-0" onSubmit={handleFormSubmit}>
                                <div className={`dropdown col-md-4 col-sm-6 col-xs-12 mb-4 scroll-into-view ${emptyFields.checkpoint ? 'has-error' : ''}`}>
                                    <div className="input-group">
                                        <span className="input-group-text"><img width="24" height="24" src="/sources/checkpoint.png" alt="checkpoint" /></span>
                                        <select className='drop-item form-control form-select sel-items' value={checkpoint} onChange={(e) => {
                                            setCheckpoint(e.target.value)
                                            if (e.target.value !== '') {
                                                setEmptyFields(prevState => ({ ...prevState, checkpoint: false }));
                                            }
                                        }}>
                                            <option value="" disabled>Checkpoint Name</option>
                                            {checkpointList.map((checkpoint, index) => (
                                                <option key={index} value={checkpoint.CheckPointName}>{checkpoint.CheckPointName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {emptyFields.checkpoint && <p className="error-message">*Checkpoint is required</p>}
                                </div>
                                <div className={`col-md-4 col-sm-6 col-xs-12 mb-4 scroll-into-view ${emptyFields.number ? 'has-error' : ''}`}>
                                    <div className="input-group">
                                        <span className="input-group-text"><FaHashtag /></span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Device ID"
                                            value={number}
                                            onChange={(e) => {
                                                handleNumberChange(e);
                                                if (e.target.value !== '') {
                                                    setEmptyFields(prevState => ({ ...prevState, number: false }));
                                                }
                                            }
                                            }
                                            disabled={editingIndex !== null}
                                        />
                                    </div>
                                    {emptyFields.number && <p className="error-message">*Device Number is required</p>}
                                    {numberError && <p className="error-message">{numberError}</p>}
                                </div>
                                <div className={`col-md-4 col-sm-6 col-xs-12 mb-4 scroll-into-view ${emptyFields.name ? 'has-error' : ''}`}>
                                    <div className="input-group">
                                        <span className="input-group-text"><FaUser /></span>
                                        <input className="form-control" type="text" value={name} placeholder="Device Local Name" onChange={(e) => {
                                            setName(e.target.value)
                                            if (e.target.value !== '') {
                                                setEmptyFields(prevState => ({ ...prevState, name: false }));
                                            }
                                        }} />
                                    </div>
                                    {emptyFields.name && <p className="error-message">*Device Local Name is required</p>}
                                </div>

                                <div className="col-md-12 col-xs-12 col-sm-12">
                                    <div className='sub-buttons'>
                                        {editingIndex !== null && (
                                            <Switch
                                                checked={status === 'Active'}
                                                onChange={() => setStatus(status === 'Active' ? 'Inactive' : 'Active')}
                                            />
                                        )}
                                        {editingIndex !== null ? (
                                            <>
                                                <button type="button" onClick={handleReset} className="reset">Reset</button>
                                                <button type="submit" className="add">Update</button>
                                            </>
                                        ) : (
                                            <>
                                                <button type="button" onClick={handleReset} className="reset">Reset</button>
                                                <button type="submit" className="add">Add</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='row mb-3'>
                            <div className="col-md-12 col-sm-8 col-xs-12">
                                <section className="scroller">
                                    <div className="filter-box d-flex search-excel">
                                        <div className="custom-input-group" style={{width: 300}}><span className="input-group-text"><FaSearch /></span>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className='col-md-12 col-sm-12 col-xs-12'>
                            <div className="table-header mb-3">
                                <span>Entries per page: </span><select value={pageSize} onChange={(e) => {
                                    setPageSize(parseInt(e.target.value));
                                    setCurrentPage(0);
                                }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                                <span className='showing-span'>
                                    Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {filteredData.length} entries
                                </span>
                            </div>
                            <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <table className="custom-table userEntriesTableSection">
                                    <thead className='t-header'>
                                        <tr>
                                            <th className='custom-header'>Sl. No.</th>
                                            <th className='custom-header'>Device ID</th>
                                            <th className='custom-header'>Device Local Name</th>
                                            <th className='custom-header'>Checkpoint Name</th>
                                            <th className='custom-header'>Status</th>
                                            <th className='custom-header'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedData.map((data, index) => (
                                            <tr key={data.id}>
                                                <td className='custom-data'>{indexOfFirstItem + index + 1}</td>
                                                <td className='custom-data'>{getSafeValue(data.DeviceNumber)}</td>
                                                <td className='custom-data'>{getSafeValue(data["Device Local Name"])}</td>
                                                <td className='custom-data'>{getSafeValue(data.CheckPointName)}</td>
                                                <td className='custom-data'>{getSafeValue(data.Status)}</td>
                                                <td className='custom-data'>
                                                    <span onClick={() => handleEdit(index)} style={{ cursor: 'pointer' }}>
                                                        <FaEdit />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination-container">
                                <ul className="pagination" role="navigation" aria-label="Pagination">
                                    <li className={`previous ${currentPage === 0 ? 'disabled' : ''}`}>
                                        <a onClick={handleFirstPageClick} role="button" tabIndex="0" aria-label="Previous page">First</a>
                                    </li>
                                </ul>
                                <Pagination pageCount={pageCount} onPageChange={handlePageClick} activePage={currentPage} />
                                <ul className="pagination" role="navigation" aria-label="Pagination">
                                    <li className={`next ${currentPage === pageCount - 1 ? 'disabled' : ''}`}>
                                        <a onClick={handleLastPageClick} role="button" tabIndex="0" aria-label="Next page">Last</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ConfirmationPopup
                showConfirmation={showConfirmation}
                handleConfirmationClose={handleConfirmationClose}
                isUpdate={isUpdatePopup}
            />
            <Footer />
        </>
    );
};
